import {createApp} from 'vue'
import App from './App.vue'
import axios from "axios";
import mitt from "mitt";
import {BootstrapVue3, vBTooltip} from "bootstrap-vue-3";
import router from "@/router";
import {createPersistedStatePlugin} from "pinia-plugin-persistedstate-2";
import {createPinia} from "pinia";
import './assets/css/main.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'bootstrap/dist/css/bootstrap.css'
export const mainAxios = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_HOST
});

export const dialogFlowAxios = axios.create({
    baseURL: 'https://dialogflow.googleapis.com/'
});

const emitter = mitt();
const pinia = createPinia()
const app = createApp(App)

app.directive('b-tooltip',vBTooltip)
app.use(BootstrapVue3)
app.use(router)
app.config.globalProperties.emitter = emitter
app.use(pinia)
pinia.use(createPersistedStatePlugin())
app.mount('#app')
